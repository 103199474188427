import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'kws-validation', component: () => import('pages/KWSPage.vue') },
      { path: 'past-validation', component: () => import('pages/PastPage.vue') },
      { path: 'intent-validation', component: () => import('pages/IntentPage.vue') },
      { path: 'kpis', component: () => import('pages/KpiPage.vue') },
      { path: 'tsv-generation', component: () => import('pages/TsvGenerationPage.vue') },
      { path: '', component: () => import('pages/IndexPage.vue') },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
